import { Flex, Heading, Image } from '@chakra-ui/react';

const NoLogs = () => (
  <Flex
    flexDir='column'
    justifyContent='center'
    alignItems='center'
    m='auto'
    data-testid='no-logs-container'
  >
    <Image
      boxSize='24'
      src='https://sitecorecontenthub.stylelabs.cloud/api/public/content/5d2c4616a0304589818f265edc4471ce'
      alt='No logs'
    />
    <Flex justifyContent='center'>
      <Heading size='md'>No Logs yet</Heading>
    </Flex>
  </Flex>
);

export default NoLogs;
