// import { UseQueryOptions } from 'react-query';
// import { getIdentityBaseUrl, IResponse } from '@sitecore-ui/portal-singular';
// import { AxiosResponse } from 'axios';
import { getIdentityBaseUrl } from '@sitecore-ui/portal-singular';
import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import { auditLogApi } from '../api/endpoints';
import { env } from 'features/common/config/envConfig';
// import { OrganizationType } from '../types';

export const getStaticLogs = () => [
  {
    system: 'Identity',
    time: '2024-09-03T14:57:55.0000000Z',
    action: 'user_login',
    entity: { id: 'ivk@xxx.xx', type: 'SupportUser', extensions: {} },
    sourceSystemUserContext: { id: 'ivk@xxx.xx' },
    extensions: {
      clientId: 'test-client-id',
      reason: 'Message with different symbols like : !@#$%^&*_-||//\\',
    },
  },
  {
    system: 'Identity',
    time: '2024-09-03T14:57:55.0000000Z',
    action: 'user_login',
    entity: { id: 'ivk@xxx.xx', type: 'SupportUser', extensions: {} },
    sourceSystemUserContext: { id: 'ivk@xxx.xx' },
    extensions: {
      clientId: 'test-client-id',
      reason: 'Message with different symbols like : !@#$%^&*_-||//\\',
    },
  },
  {
    system: 'Identity',
    time: '2024-09-03T14:57:55.0000000Z',
    action: 'user_login',
    entity: { id: 'ivk@xxx.xx', type: 'SupportUser', extensions: {} },
    sourceSystemUserContext: { id: 'ivk@xxx.xx' },
    extensions: {
      clientId: 'test-client-id',
      reason: 'Message with different symbols like : !@#$%^&*_-||//\\',
    },
  },
  {
    system: 'Identity',
    time: '2024-09-03T14:57:55.0000000Z',
    action: 'user_login',
    entity: { id: 'ivk@xxx.xx', type: 'SupportUser', extensions: {} },
    sourceSystemUserContext: { id: 'ivk@xxx.xx' },
    extensions: {
      clientId: 'test-client-id',
      reason: 'Message with different symbols like : !@#$%^&*_-||//\\',
    },
  },
];

const baseUrl = getIdentityBaseUrl(env.toLowerCase());

export const useGetAuditLogs = ({
  page,
  pageSize,
  action = 'user_login',
  entityType = 'SupportUser',
  enabled = true,
}: {
  page: number;
  pageSize: number;
  action?: string;
  entityType?: string;
  enabled?: boolean;
}) => {
  const { get_logs } = auditLogApi;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const url = `${baseUrl}${get_logs.url({
    page,
    pageSize,
    action,
    entityType,
  })}`;

  return useAuthQuery(
    ['organizations/organization'],
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (axiosInstance) => {
      //   return axiosInstance?.get<AxiosResponse<IResponse<OrganizationType>>>(
      //     url,
      //   );
      return getStaticLogs();
    },
    { enabled },
    get_logs.scope,
  );
};
