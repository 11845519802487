import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Code,
  DrawerBody,
  DrawerFooter,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import FieldError from 'features/common/components/Form/FieldError';
import { connectionInputFields, isDomain, isRequiredField } from './utils';
import { useCreateConnection } from './api';
import { ssoProviders } from 'features/sso/sso-component';
import { useParams } from 'react-router';
import { getIn } from 'formik';
import { useHistory } from 'react-router-dom';
import { ssoRoute } from './sso-routes';
import { createConectionSchema } from 'features/sso/validations/connectionSchema';
import { env } from 'features/common/config/envConfig';
import { Select as ReactSelect } from 'chakra-react-select';
import PasswordInput from './components/password-input';
import { InputTag } from 'features/common/components/InputTag';

type SelectOption = {
  label?: string;
  value?: string;
};

type ConnectionFormPropTypes = {
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  onCloseDrawer: () => void;
};

const Form = ({
  initialFocusRef,
  setIsDirty,
  onCloseDrawer,
}: ConnectionFormPropTypes) => {
  const { ssoProvider } = useParams<{ ssoProvider: string }>();
  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isValidating,
    isSubmitting,
    dirty,
    isInEditMode,
    isLoading,
    isFetched,
    connectionError,
    setFieldValue,
    setFieldTouched,
    setErrors,
    initialValues,
  } = useCreateConnection(ssoProvider);
  const history = useHistory();
  const toast = useToast();

  // This was needed to help jest invalidate the dependency of connection error because it was causing a memory leak
  const conErrorPrimitive = Boolean(connectionError);

  const provider = isInEditMode
    ? Object.keys(initialValues.options)[0]
    : ssoProvider;
  const { label: providerLabel } =
    ssoProviders.find((ssop) => ssop.value === provider) || {};

  const getPasswordValue = (
    fieldValue: string,
    fakeMaskedValue: string | undefined,
  ) => {
    if (
      //@ts-ignore
      touched.options?.oidc?.clientSecret ||
      //@ts-ignore
      initialValues?.options?.oidc?.type === 'FrontChannel'
    ) {
      return fieldValue;
    }

    if (isInEditMode) {
      return fieldValue || fakeMaskedValue;
    }

    return fieldValue;
  };

  const passwordIsInEditMode =
    //@ts-ignore
    isInEditMode && initialValues.options?.oidc?.type !== 'FrontChannel';

  // if connectionError is not null, then show toast and redirect to ssoRoute
  useEffect(() => {
    if (!conErrorPrimitive) return;
    // @ts-ignore
    const error = connectionError?.response?.data;
    toast({
      description: `${
        error?.title ? `Connection:  ${error?.title}` : 'Something went wrong.'
      }`,
      status: 'error',
      isClosable: true,
    });

    history.replace(`${ssoRoute}${history.location.search}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conErrorPrimitive, toast]);

  useEffect(() => setIsDirty(dirty), [dirty, setIsDirty]);

  const onTagInputChange = useCallback(
    (emails: string[]) => {
      setFieldValue('emailDomains', emails);
      setErrors({});
    },
    [setErrors, setFieldValue],
  );

  return (
    <>
      <DrawerBody>
        <form
          onSubmit={handleSubmit}
          data-testid='sso-connection-form'
          id='sso-connection-form'
        >
          {!isInEditMode && (
            <Flex>
              <Alert status='info' data-testid='ssoConnectionForm-description'>
                <AlertIcon />
                <AlertDescription>
                  Before you set up a new SSO connection, you need to register
                  Sitecore Cloud Portal with your Identity provider.{' '}
                  <Link
                    isExternal
                    href='https://doc.sitecore.com/portal/en/developers/sitecore-cloud-portal/introduction-to-the-sitecore-cloud-portal.html?contextId=add-openid-sso-connection'
                  >
                    Learn more
                  </Link>
                </AlertDescription>
              </Alert>
            </Flex>
          )}
          <Text my={8} data-testid='sso-connection-form-provider-type'>
            Provider type: {providerLabel}
          </Text>
          <Flex direction='column' gap={8}>
            {connectionInputFields.map(
              ({
                label,
                name,
                description,
                testId,
                isEditable,
                isVisible,
                options,
                fakeMaskedValue,
              }) => {
                const fieldValue = getIn(values, name);

                const passwordValue = getPasswordValue(
                  fieldValue,
                  fakeMaskedValue,
                );
                const isFieldDisabled = isInEditMode && !isEditable;

                return (
                  isVisible(values) && (
                    <FormControl
                      isInvalid={getIn(errors, name) && getIn(touched, name)}
                      isRequired={isRequiredField(
                        name,
                        values,
                        createConectionSchema.oidc,
                      )}
                      key={name}
                      isDisabled={isFieldDisabled}
                    >
                      <Flex>
                        <FormLabel htmlFor={name} data-testid={'label-' + name}>
                          <T _str={label} />
                        </FormLabel>
                      </Flex>
                      <Skeleton
                        isLoaded={isInEditMode ? isFetched : true}
                        data-testid={'inputWrapper-' + name}
                      >
                        {label === 'Client secret' ? (
                          <PasswordInput
                            name={name}
                            key={name}
                            inputId={name}
                            data-testid={testId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInEditMode={passwordIsInEditMode}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            value={passwordValue}
                            autoComplete='off'
                          />
                        ) : (
                          <>
                            {options ? (
                              <ReactSelect
                                selectedOptionColorScheme='primary'
                                useBasicStyles
                                name={name}
                                key={name}
                                inputId={`${name}`}
                                data-testid={testId}
                                value={{
                                  value: fieldValue,
                                  label: options.find(
                                    (opt) => opt.value === fieldValue,
                                  )?.label,
                                }}
                                onChange={(data) => {
                                  const event = {
                                    target: { name, value: data?.value },
                                  };
                                  handleChange(event);
                                }}
                                onBlur={() => {
                                  handleBlur({ target: { name: name } });
                                }}
                                options={options}
                                formatOptionLabel={({
                                  label,
                                  value,
                                }: SelectOption) => (
                                  <Text data-testid={`option-${value}`}>
                                    <T _str={label} />
                                  </Text>
                                )}
                              />
                            ) : label === 'Email domains' ? (
                              <InputTag
                                name={name}
                                tags={values.emailDomains ?? []}
                                onChange={onTagInputChange}
                                onBlur={handleBlur}
                                validateTag={isDomain}
                                placeholder='example.com'
                                ref={initialFocusRef}
                                isInvalid={
                                  getIn(errors, name) && getIn(touched, name)
                                }
                                isDisabled={isFieldDisabled}
                                dataTestId={testId}
                              />
                            ) : (
                              <Input
                                name={name}
                                key={name}
                                // @ts-ignore
                                inputId={name}
                                data-testid={testId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={fieldValue}
                                isDisabled={isFieldDisabled}
                                autoComplete='off'
                              />
                            )}
                          </>
                        )}
                      </Skeleton>
                      {typeof description === 'string' ? (
                        <FormHelperText>{description}</FormHelperText>
                      ) : (
                        <>{description}</>
                      )}
                      <FieldError
                        touched={getIn(touched, name)}
                        error={getIn(errors, name)}
                        testId={testId}
                      />
                    </FormControl>
                  )
                );
              },
            )}
            <FormControl>
              <Flex direction='column'>
                <FormLabel>
                  <T _str={'Callback URL'} />
                </FormLabel>
                <Box>
                  You may need to add this URL (some providers call it a
                  redirect URI) when you register the Sitecore Cloud Portal with
                  your identity provider:
                  <Code>{`${singInRedirectCallbackUri()}/login/callback`}</Code>
                </Box>
              </Flex>
            </FormControl>
          </Flex>
        </form>
      </DrawerBody>
      <DrawerFooter justifyContent='flex-end' alignItems='flex-end'>
        <ButtonGroup>
          <Button
            type='button'
            variant='ghost'
            data-testid='ssoConnectionForm-cancelButton'
            onClick={onCloseDrawer}
            isDisabled={isValidating || isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='solid'
            data-testid='ssoConnectionForm-submitButton'
            isLoading={isValidating || isSubmitting}
            isDisabled={!isValid || !dirty || isLoading}
            form='sso-connection-form'
          >
            Save
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

function singInRedirectCallbackUri() {
  const environment = env.toLowerCase() as string;

  const map = {
    dev: 'https://auth-staging-1.sitecore-staging.cloud',
    staging: 'https://auth-staging-1.sitecore-staging.cloud',
    'pre-production': 'https://auth-beta.sitecorecloud.io',
    production: 'https://auth.sitecorecloud.io',
  }[environment];

  return map;
}

export default Form;
