import { t } from '@transifex/native';
import { Link } from 'react-router-dom';
import { history } from '../../../common/history/history';
import { format } from 'date-fns';
import { Avatar } from '@chakra-ui/react';
import { OrganizationMember, OrganizationMemberAction } from 'gql/graphql';

interface IMemberRowDataItem {
  id: string;
  givenName: string;
  familyName: string;
  lastLogin: string;
  orgRole: string;
  actions?: OrganizationMemberAction[] | null;
}

export const transformDataForTable = (
  data?: OrganizationMember[] | null,
): IMemberRowDataItem[] =>
  data?.map(
    ({
      id,
      givenName,
      familyName,
      email,
      organizationRoles,
      lastLogin,
      picture,
      appearance,
    }) => {
      return {
        id,
        givenName: givenName ?? '',
        familyName: familyName ?? '',
        email,
        orgRole: organizationRoles?.nodes?.[0]?.name || 'No Role',
        lastLogin,
        picture,
        actions: appearance?.memberActions?.nodes,
      };
    },
  ) ?? [];

type GetTableOptions = {
  page: number;
  allowMemberAccess: (
    memberId: string | undefined,
    organizationRole: string,
  ) => boolean;
};

export const getTableOptions = ({ page }: GetTableOptions) => {
  return {
    rowActions: (memberRow: { original: IMemberRowDataItem }) => {
      const { actions } = memberRow.original as IMemberRowDataItem;
      return actions?.map(({ name, displayName, link }, i) => ({
        id: i,
        label: displayName,
        onClick: () => {
          history.push(
            `/admin/members/page/${page}/${link?.to}${history.location.search}`,
          );
        },
        'data-behavior-analytics-id': `${name.toLowerCase()} - submenu`,
      }));
    },
    columns: [
      {
        Header: '',
        id: 'avatar',
        accessor: (user: any) => (
          <Link
            to={`/admin/members/page/${page}/details/${user.id}${history.location.search}`}
            data-behavior-analytics-id='view details - avatar'
          >
            <Avatar
              size='sm'
              name={`${user.givenName} ${user.familyName}`}
              src={user.picture}
            />
          </Link>
        ),
        width: '1%',
        disableSortBy: true,
      },

      {
        Header: t('Given name'),
        accessor: 'givenName',
        disableSortBy: true,
      },
      {
        Header: t('Family name'),
        accessor: 'familyName',
        disableSortBy: true,
      },
      {
        Header: t('Email'),
        accessor: 'email',
        disableSortBy: true,
      },
      {
        Header: t('Access'),
        accessor: 'orgRole',
        disableSortBy: true,
      },
      {
        Header: t('Last login'),
        id: 'lastLogin',
        accessor: (user: any) =>
          format(new Date(user.lastLogin).getTime(), 'MMM d, yyyy'),
        disableSortBy: true,
      },
    ],
  };
};
