import { Button, Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import RecommendationsCard from './RecommendationsCard';
import { getCtaCallback } from 'features/domains/portal/content/utils';
import { useHistory } from 'react-router';
import {
  Link,
  Maybe,
  useGetRecommendationCardsQuery,
  useCloseRecommendationCardsMutation,
  GetRecommendationCardsDocument,
  RecommendationCard,
} from 'gql/graphql';
import { useMainStateDispatch } from 'features/main/context';
import { useEffect, useMemo, useState } from 'react';
import { transformCardData } from './utils';

const Recommendations = () => {
  const history = useHistory();
  const [, dispatch] = useMainStateDispatch();
  const { data } = useGetRecommendationCardsQuery();
  const [closeRecommendationCards] = useCloseRecommendationCardsMutation();
  const [uiCards, setUiCards] = useState<RecommendationCard[]>([]);
  const recommendationCards = useMemo(() => {
    const partialCards =
      data?.personalizedContent?.recommendationCards.slice(0, 3) ?? [];
    return transformCardData(partialCards);
  }, [data]);

  useEffect(() => setUiCards(recommendationCards), [recommendationCards]);

  const clearAllHandler = () => {
    const cardIds = uiCards.map(({ cardId }) => cardId);
    setUiCards([]);
    closeRecommendationCards({
      variables: {
        cardIds,
      },
      refetchQueries: [GetRecommendationCardsDocument],
    });
  };

  const onCardClickHandler = (link: Maybe<Link> | undefined) => {
    if (!link) return;

    return getCtaCallback({ link, history, dispatch: dispatch })();
  };

  const onCloseClickHandler = (id: string) => {
    const filteredCards = uiCards.filter(({ cardId }) => cardId !== id);
    setUiCards(filteredCards);
    closeRecommendationCards({
      variables: {
        cardIds: [id],
      },
      refetchQueries: [GetRecommendationCardsDocument],
    });
  };

  if (uiCards.length === 0) return null;

  return (
    <Flex
      data-testid='recommendations'
      flexDirection='column'
      width='full'
      gap={5}
    >
      <Flex width='full' justifyContent='space-between'>
        <Heading size='md' noOfLines={1}>
          Recommended for you
        </Heading>
        <Button variant='link' onClick={clearAllHandler}>
          Clear all
        </Button>
      </Flex>
      <Grid
        width='full'
        templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
        gap={4}
      >
        {uiCards.map((c) => (
          <GridItem w='full' h='full' key={c.cardId}>
            <RecommendationsCard
              cardId={c.cardId}
              icon={c.icon}
              title={c.title}
              description={c.description}
              onClick={() => onCardClickHandler(c.link)}
              onClose={() => onCloseClickHandler(c.cardId)}
            />
          </GridItem>
        ))}
      </Grid>
    </Flex>
  );
};

export default Recommendations;
