import { Environment } from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';
import { RecommendationCard } from 'gql/graphql';

const baseDeployUrl = {
  dev: 'https://deploy-dev.sitecore-staging.cloud',
  qa: 'https://deploy-qa.sitecore-staging.cloud',
  staging: 'https://deploy-staging.sitecore-staging.cloud',
  'pre-production': 'https://deploy-preprod.sitecorecloud.io',
  preprod: 'https://deploy-preprod.sitecorecloud.io',
  production: 'https://deploy.sitecorecloud.io',
};

export const XmcCloudDeployCardId = 'XMCloudDeploy';

export const transformCardData = (
  cards: RecommendationCard[],
): RecommendationCard[] =>
  cards.map((card) =>
    card.cardId === XmcCloudDeployCardId
      ? {
          ...card,
          link: {
            type: card.link?.type ?? '',
            to: baseDeployUrl[env as Environment],
          },
        }
      : card,
  );
