import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const titles = {
  '/': 'Sitecore Cloud Portal',
  '/admin/members/page/1': 'Team members - Sitecore Cloud Portal',
  '/admin/invites/page/1': 'Invitations - Sitecore Cloud Portal',
  '/admin/multifactor-authentication':
    'Multi-factor authentication - Sitecore Cloud Portal',
  '/profile': 'Manage account - Sitecore Cloud Portal',
  '/admin/sso': 'Single sign-on - Sitecore Cloud Portal',
  '/admin/support-access': 'Product Support Access - Sitecore Cloud Portal',
} as const;

type Titles = typeof titles;
type Pathname = keyof Titles;

const useSetDocumentTitle = () => {
  const location = useLocation();

  useEffect(() => {
    document.title =
      titles[location?.pathname as Pathname] ?? 'Sitecore Cloud Portal';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSetDocumentTitle;
