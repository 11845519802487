const calBaseUrl = 'https://audit-api-euw.sitecorecloud.io';

export const auditLogApi = {
  get_logs: {
    url: ({
      page,
      pageSize,
      action,
      entityType,
    }: {
      page: number;
      pageSize: number;
      action: string;
      entityType: string;
    }) =>
      `${calBaseUrl}/api/v1/Logs?page=${page}&pageSize=${pageSize}&action=${action}&entity.type=${entityType}`,
    scope: 'audit.logs:r',
  },
};
