import {
  getSnowBaseUrl,
  getFeatureFlagBaseUrl,
  hostnames,
  env,
} from './envConfig';

const snowBaseUrl = getSnowBaseUrl();
const featureFlagsUrl = getFeatureFlagBaseUrl();

const snow = {
  create_ticket: {
    scope: '',
    url: `${snowBaseUrl}/sc_dxp_integration/supportcases`,
  },
};

const toAzureFeatureLabel = (envName: string) => {
  switch (envName) {
    case hostnames.localhost:
    case hostnames.internal:
      return env.toLowerCase();
    case hostnames.dev:
      return 'dev';
    case hostnames.qa:
      return 'qa';
    case hostnames.staging:
      return 'staging';
    case hostnames['pre-production']:
      return 'preprod';
    case hostnames.production:
    default:
      return 'prod';
  }
};

const featureFlags = {
  scope: 'featureflags',
  url: `${featureFlagsUrl}/flags?key=portal&labelfilter=${toAzureFeatureLabel(
    window.location.hostname,
  )}`,
};

export default {
  snow,
  featureFlags,
};

/*
    client side config, not to be used for sensitive data
*/
