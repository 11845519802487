import { format } from 'date-fns';
import { Log, Logs } from './types';

export const getTableData = (logs: Logs) =>
  logs.map(({ time, entity: { id }, extensions: { reason, clientId } }) => ({
    time,
    email: id,
    id,
    clientId,
    description: reason,
  })) ?? [];

export const tableOptions = {
  columns: [
    {
      id: 'email',
      Header: 'Product support engineer',
      accessor: 'email',
      disableSortBy: true,
    },
    {
      id: 'loginTime',
      Header: 'Login time',
      accessor: ({ time }: Partial<Log>) =>
        format(new Date(time!).getTime(), 'MMM d, yyyy h:mmaaa'),
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
    },
  ],
};
