import { Box } from '@chakra-ui/react';
import {
  DataTable,
  DataTableSortingRule,
} from 'features/common/components/DataTable';
import { useMemo } from 'react';
import { useGetAuditLogs } from './hooks/useGetAuditLogs';
import NoLogs from './NoLogs';
import { tableOptions, getTableData } from './utils';

const sortBy: DataTableSortingRule[] = [];
const onChange = () => void 0;
const page = 1;
const pageSize = 10;

const SupportAccessTable = () => {
  const { data: logs, isLoading } = useGetAuditLogs({
    page,
    pageSize,
  });

  const data = useMemo(() => getTableData(logs ?? []), [logs]);
  return logs?.length || isLoading ? (
    <Box w='full'>
      <DataTable
        columns={tableOptions.columns}
        //   pagination={pagination}
        //   totalItems={totalCount}
        data={data}
        onChange={onChange}
        //   page={page}
        //   pageSize={pageSize}
        isLoading={isLoading}
        sortBy={sortBy}
        theaderProps={{
          'data-behavior-analytics-id': 'support access sorting',
        }}
        paginationProps={{
          'data-behavior-analytics-feature': ' support access pagination',
        }}
        data-behavior-analytics-feature='support access table'
      />
    </Box>
  ) : (
    <NoLogs />
  );
};

export default SupportAccessTable;
